import css from './index.module.css'
import {Button} from "react-bootstrap";
import refImg from '../../assets/Ref.png';
import union from '../../assets/Union.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import API from '../../redux/services/Api';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import React from 'react';

const SocialExamples = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [exampleList, setExampleList] = useState<any>({})

    const initEditData = {
        exampleId: null,
        title: "",
        desc: "",
        exImg: null,
        exAiImg: [],
        page: '',
        deleteIndex: [],
        updateIndex: []
    }
    const [editData, setEditData] = useState<any>(initEditData)
    
    useEffect(() => {
        getExampleData()
    },[])

    const getExampleData = async() => {
        const response:any = await API.Post("admin/socialExamples", {}, userDetails, dispatch)
        if(response.status === "success"){
            setExampleList(response.data)
        }
    }

    function handleOnDragEnd(result:any) {
        if (!result.destination) return;
        const items = Array.from(exampleList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setExampleList((pre:any) => (items));
    }

    const updateExampleIndex = async() =>{
        try{
            const reqData = exampleList.map((e:any) => {
                return {
                    _id: e._id,
                    exIndex:e.exIndex
                }
            })
            const response:any = await API.Post("admin/social-example-index-handler", {exIndex:reqData}, userDetails, dispatch)
            if(response.status === "success"){
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const onHandleInputValue = (e:any) => {
        setEditData((prevData:any) =>({
            ...prevData,
            [e.target.name]:[e.target.value]
        }))
    }

    const onHandleMainImage = (e:any) => {
        setEditData((prevData:any) =>({...prevData, exImg: e.target.files[0]}));
    }

    const onHandleOtherImage = (e:any) => {
        setEditData((prevData:any) =>({
            ...prevData, 
            exAiImg: [...prevData.exAiImg, ...e.target.files]
        }));
    }

    const onHandleOtherUpdateImage = (e:any, type:string, imgIndex:number) => {
     
        let editDatas:any = editData;
        if(type === "exImg"){
            editDatas[type] = e.target.files[0]
        }else{
            editDatas[type][imgIndex] = e.target.files[0];
        }
        setEditData((prevData:any) => ({
            ...prevData,
            editDatas: editDatas,
            updateIndex:[...prevData.updateIndex, imgIndex ]
        }))
    }

    const uploadExample = async(e:any) => {
        e.preventDefault()
        try {
            if(!editData.exImg){
                toast.error("Please select main image")
                return false;
            }

            if(e.target.page.value.trim() == "" || e.target.title.value.trim() == "" || e.target.desc.value.trim() == ""){
                toast.error("Please fill all fields")
                return false;
            }

            let formData = new FormData();
            formData.append("page", e.target.page.value.trim())
            formData.append("title", e.target.title.value.trim())
            formData.append("desc", e.target.desc.value.trim())

            if(editData.exampleId){
                formData.append("exampleId", editData.exampleId)
            }

            if(typeof editData.exImg !== 'string'){
                formData.append("exImg", editData.exImg)
            }

            editData.exAiImg.map((files:any) => {
                if(typeof files !== 'string'){
                    formData.append("exAiImg", files)
                }
            })

            if(editData.deleteIndex.length > 0){
                formData.append("deleteIndex", editData.deleteIndex.toString())
            }

            if(editData.updateIndex.length > 0){
                formData.append("updateIndex", editData.updateIndex.toString())
            }

            const response:any = await API.Post("admin/social-example-handler", formData, userDetails, dispatch, true)
            if(response.status === "success"){
                e.target.reset()
                setEditData(initEditData)
                getExampleData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }
    
    const editExample = (imgData:any) => {
        setEditData({
            exampleId: imgData._id,
            title: imgData.title,
            desc: imgData.desc,
            exImg: imgData.image,
            exAiImg: imgData.aiimages,
            page: imgData.page,
            deleteIndex: [],
            updateIndex: []
        })
    }

    const deleteAiImages = (type:string, imgIndex:number) => {
        let editDatas:any = editData;
        if(type === "exImg"){
            editDatas[type] = null
        }else{
            editDatas[type][imgIndex] = null;
        }
        setEditData((prevData:any) => ({
            ...prevData,
            editDatas: editDatas,
            deleteIndex:[...prevData.deleteIndex, imgIndex ]
        }))
    }

    const deleteAiMainImages = (type:string) => {
        let editDatas:any = editData;
            editDatas[type] = null
        
        setEditData((prevData:any) => ({
            ...prevData,
            editDatas: editDatas
        }))
    }

    const resetEditData = () => {
        setEditData(initEditData)
    }

    const deleteExample = async(exampleId:string) => {
        const response:any = await API.Post("admin/delete-social-example", {exampleId: exampleId}, userDetails, dispatch)
        if(response.status === "success"){
            getExampleData()
        }
    }

    return (
        <>
            <div className={`card examplePage p-3 mb-3 ${css.tableContainer}`}>
                <form action='#' method='post' onSubmit={uploadExample}>
                    <div className={`d-flex justify-content-between align-items-center ${css.actionsButton}`}>
                        <label>Create Example</label>
                        <div className="d-flex gap-3">
                            <Button type="button" variant="success" className={css.actionButtonCancel} onClick={resetEditData}>Cancel</Button>
                            <Button type="submit" variant="success" className={css.actionButtonSave}>Save Example</Button>
                        </div>
                    </div>
                    <div className="d-inline-block w-100 gap-3">
                        <div className='row'>
                            <div className="text-start col-md-3">
                                <label className={css.label}>Main</label>
                                <div className={`${css.imagepart} d-flex align-items-center`}>
                                    <label className="uplodImageCard" htmlFor="exImg"></label>
                                    <input type="file" id="exImg" onChange={onHandleMainImage} className='d-none'/>
                                    <div className='editView'>
                                        {editData.exImg && (
                                            <div className='editView'>
                                                <img key={"exImg"} src={(typeof editData.exImg === 'string')?(editData.exImg):URL.createObjectURL(editData.exImg)} alt='' className={`exampleimage mr-05`}/>
                                                <div className='actionBtn'>
                                                    <button type='button' onClick={() => deleteAiMainImages("exImg")}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon></button>
                                                    <label htmlFor={"exImgEx"}><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon></label>
                                                    <input type="file" id={"exImgEx"} name={'exImgEx'} onChange={onHandleMainImage} className='d-none'/>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="text-start col-md-9">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="mb-3 text-start">
                                            <label className="Settings_label__4KH6A form-label" htmlFor="formBasicEmail">Social Media Name</label>
                                            <input name="page" required type="text" id="formBasicEmail" value={editData.page} onChange={onHandleInputValue} className="form-control"
                                            />
                                        </div>
                                        <div className="mb-3 mt-4 text-start">
                                            <label className="Settings_label__4KH6A form-label" htmlFor="formBasicEmail">Title</label>
                                            <input name="title" required type="text" id="formBasicEmail" value={editData.title} onChange={onHandleInputValue} className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3 text-start">
                                            <label className="Settings_label__4KH6A form-label" htmlFor="formBasicDesc">Description</label>
                                            <textarea name="desc" required id="formBasicDesc" rows={5} value={editData.desc} onChange={onHandleInputValue} className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="text-start" style={{width:'100%'}}>
                                <label className={css.label}>Ai Generated Photos (3:2 | 1200x800px)</label>
                                <div className={`d-flex align-items-center ${css.imagepart}`}>
                                    <label className="uplodImageCard" htmlFor="otherImg"></label>
                                    <input type="file" id="otherImg" onChange={onHandleOtherImage} multiple className='d-none'/>
                                    <div className={`${css.imagesLists}`}>
                                        <div className='d-flex'>
                                            {editData.exAiImg.map((img:any, editIndex:number) => (
                                                <React.Fragment key={editIndex+"__img"}>  
                                                    {img && (
                                                        <div className='editView'>
                                                            <img key={editIndex} src={(typeof img === 'string')?(img):URL.createObjectURL(img)} alt='' className={'exampleimage'}/>
                                                            <div className='actionBtn'>
                                                                <button type='button' onClick={() => deleteAiImages("exAiImg", editIndex)}><FontAwesomeIcon icon={faClose}></FontAwesomeIcon></button>
                                                                <label htmlFor={"exAiImg"+editIndex}><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon></label>
                                                                <input type="file" id={"exAiImg"+editIndex} name={'exAiImg'+editIndex} onChange={(e:any) => onHandleOtherUpdateImage(e, "exAiImg", editIndex)} className='d-none'/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </form>
            </div>

            <div className={`card examplePage p-3 mb-3 ${css.tableContainer}`}>
                <div className={`d-flex justify-content-between align-items-center ${css.actionsButton}`}>
                    <label></label>
                    <div className="d-flex gap-3">
                        <Button type="button" onClick={updateExampleIndex} variant="success" className={css.actionButtonSave}>Update List Index</Button>
                    </div>
                </div>
                <div className='d-flex exImgList border-bottom-1px'>
                    <label className='indexRow p-2'>Social Title</label>
                    <div className='text-center exImg p-2'>Main</div>
                    <div className='otherImg text-center p-2'>Home Screen Featured</div>
                    <div className='actionMenu p-2'>Action</div>
                </div>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided) => (
                            <ul className="characters p-0" {...provided.droppableProps} ref={provided.innerRef}>
                                {exampleList.length > 0 && exampleList.map((itemData:any, index:number) => (
                                    <React.Fragment key={itemData._id+"_drag"}>
                                        <Draggable key={itemData._id} draggableId={itemData._id} index={index}>
                                        {(provided) => (
                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className='d-flex exImgList'>
                                                    <label className='indexRow'>{itemData.page}</label>
                                                    <div className='exImg text-center'>
                                                        <img src={union} className='unionIcon'/>
                                                        {itemData.image !== ""?(
                                                            <img src={itemData.image} alt='' className={css.footerImage}/>
                                                        ):(
                                                            <img src={refImg} alt='' className={css.footerImage}/>
                                                        )}
                                                    </div>
                                                    <div className='otherImg d-flex'>
                                                        {itemData.aiimages && itemData.aiimages.map((img:string, imgIndex:number) => (
                                                            <div className='editView' key={imgIndex+"_drag_img"}>
                                                                <img key={imgIndex} src={img} alt='' className={css.footerImage}/>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className='actionMenu'>
                                                        <div className='d-flex'>
                                                            <button type='button' className='transparentBtn' onClick={() => editExample(itemData)}>
                                                                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                            </button>
                                                            <button type='button' className='transparentBtn' onClick={() => deleteExample(itemData._id)}>
                                                                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                        </Draggable>
                                    </React.Fragment>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    )
}

export default SocialExamples