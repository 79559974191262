import css from './index.module.css';
import Form from 'react-bootstrap/Form';
import {Col, Row} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState} from "react";
import apple from '../../assets/icons8-apple-96.png';
import android from '../../assets/icons8-android-96.png';
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import DataTable from "react-data-table-component";

const Country = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalRows, setTotalRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [searchData, setSerachData] = useState({
        type:""
    })

    const handleActiveButton = (buttonName: string) => {
        setSerachData(prevState => ({...prevState, type:buttonName}));
    };

    const columns = useMemo(
        () => [
            {
                name: "Country",
                selector: (row:any) => row.name
            },
            {
                name: "Status",
                cell: (row:any) => row.action
            }
        ],
        []
      );


    useEffect(() => {
        getCountryData()
    },[searchData.type, currentPage, rowsPerPage, searchText]);
    

    const getCountryData = async() => {

        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = {...search, type: (searchData.type === 'targeted' ? 'true': 'false')}
        }

        setLoading(true)
        const response:any = await API.Post("admin/countrys", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            const bodyData = tmpData.map((itemData:any, index:number) => {
                return {
                    name: itemData.name,
                    action: (
                        <div className={css.toggleAction}><Form>
                            <Form.Check type="switch" id="disabled-custom-switch" onChange={(e) => changeCountryStatus(e, itemData._id )} checked={itemData.status}/></Form>
                        </div>),
                };
            });
            setBody(bodyData)
            setTotalRows(response.data.total)
        }
        setLoading(false)
    }

    const changeCountryStatus = async(e:any, countryid:string) => {
        try {
            const response:any = await API.Post("admin/country-status-change", {countryId: countryid}, userDetails, dispatch)
            if(response.status === "success"){
                getCountryData();
                toast.success(response.message)
            }            
        } catch (error) {
            console.log(error)
        }
    }

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }

    return (
        <div className={`card p-3 ${css.tableContainer}`}>
                <Row className="mb-4 p-2">
                    <Col xs={12} sm={12} md={6} lg={5}
                         className="d-flex flex-col align-items-center justify-content-between gap-3">
                        <div className={`btn-group ${css.tableButtons}`} role="group">
                            <button type="button" onClick={() => handleActiveButton('')}
                                    style={{borderRadius: '50px 0 0 50px'}}
                                    className={searchData.type === '' ? css.activeButton : css.allUserFilterButtons}>All
                            </button>
                            <button type="button" onClick={() => handleActiveButton('targeted')}
                                    className={searchData.type === 'targeted' ? css.activeButton : css.freeFilterButtons}>Targeted
                            </button>
                            <button type="button" onClick={() => handleActiveButton('not targeted')}
                                    style={{borderRadius: '0 50px 50px 0'}}
                                    className={searchData.type === 'not targeted' ? css.activeButton : css.paidFilterButtons}>Not targeted
                            </button>
                        </div>
                    </Col>
                    <Col xs={8} sm={8} md={4} lg={7} className="d-flex search-input flex-col align-items-end">
                        <InputGroup className={css.searchInput}>
                            <InputGroup.Text id="basic-addon1">
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </InputGroup.Text>
                            <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                        </InputGroup>
                    </Col>
                </Row>

                <DataTable
                        pagination
                        paginationServer
                        columns={columns}
                        data={body}
                        progressPending={loading}
                        paginationTotalRows={totalRows}
                        selectableRowsNoSelectAll={false}
                        paginationDefaultPage={currentPage}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        paginationPerPage={rowsPerPage}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                    />
                
               
            {/* </DatatableWrapper> */}
        </div>
    )
}

export default Country