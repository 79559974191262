import * as types from '../actions/types';

export interface appStateIF {
    username: string|null;
    accessToken: string|null;
    refreshToken: string|null;
}

export const initialState: appStateIF = {
    username: null,
    accessToken: null,
    refreshToken:null,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, ...action.payload };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                username: action.payload.username,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            };
        case types.LOGOUT:
            return {
                ...state,
                username: null,
                accessToken: null,
                refreshToken: null
            };
        default:
            return state;
    }
};

export default reducer;
