import React from 'react';
import './App.css';
import Routes from "./Routes";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from './redux/store';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
