// import { API_URL } from "../../config";
import axios from 'axios'
// import {updateAccessToken} from './AuthService'
// import preval from 'babel-plugin-preval/macro'
// import { useDispatch, useSelector } from 'react-redux';
import { logout, validateAccessToken } from '../actions/AuthAction';
import { setLoading } from '../actions/ConfigAction';
// import { ReducerStateIF } from '../reducers';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';

/**
 * Get request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers    Request header
 */
export const get = (path: string, params?: any, headers?: any) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(path, { headers:{'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN, 'Content-Type': 'application/json'}, data:JSON.stringify(params) })
                .then(function(getResponse:any){
                    return JSON.parse(getResponse.data)}
                )
                .then(resolve)
                .catch(reject);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Post request
 * @param path      API url path
 * @param params    Request parameters
 */


// api status response
// 0 - success
// 1 - require fieldset
// 2 - faild to find
// 3 - other error
// 4 - already exiest
// 5 - JWT INvalid


export const Post = async(path: string, params?: any, headers?: any, dispatch?:any, isMultiPart:boolean = false) => {
    return new Promise((resolve, reject) => {
        try {
            dispatch(setLoading(true));

            let headerParam:any = {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': (!isMultiPart)?'application/json':"multipart/form-data", 
                'Authorization': "Bearer "+headers?.accessToken, 
            }

            axios.defaults.baseURL = process.env.REACT_APP_API_URI;
            axios.post(path, params, { headers: headerParam })
            .then(function(getResponse:any){

                const jsonData:any = getResponse.data
                
                const errorCode = jsonData.result_code;
                if(errorCode === 3 || errorCode === 4){
                    toast.error(jsonData.result.error);
                }else if(errorCode === 2 || errorCode === 1 ){
                    let rData = jsonData?.result?.error;
                    let errors = ''
                    if(rData){
                        errors = rData
                    }else{
                        errors = "Somethig is wrong.";
                    }
                    toast.error(errors);
                    dispatch(setLoading(false))
                }else if(errorCode === 5){
                    validateAccessToken(headers)
                    dispatch(logout())
                    dispatch(setLoading(false));
                    window.location.href = '/login'
                }

                dispatch(setLoading(false))
                return jsonData.result;
                
            })
            .then(resolve)
            // .catch(reject)
            .catch(function(e:any){
                let rData = e.response?.data?.result?.error;
                if(rData){
                    console.log(rData)
                    let errors = "";
                    if(rData.index === 0){
                        Object.keys(rData.keyValue).forEach((key) => {
                            errors += key+" : "+rData.keyValue[key]+"\r\n";
                        });
                        errors += "Already Exiest";
                    }else{
                        Object.keys(rData.errors).forEach((key) => {
                            errors += rData.errors[key].message+"\r\n";
                        });
                    }
                    toast.error(errors);
                }
                dispatch(setLoading(false));
                reject(e) 
            });
            
        } catch (error:any) {
            dispatch(setLoading(false));
            reject(error);
        }
    });
};


// export const getImage = async(path: string, params?: any, headers?: any, dispatch?:any) => {
//     return new Promise((resolve, reject) => {
//         try {
//             axios.defaults.baseURL = '';
//             axios.get(path,{ headers:{
//                 'Access-Control-Allow-Origin':'*',
//                 'Content-Type': 'application/json', 
//                 'Authorization': "Bearer "+headers?.accessToken, 
//                 'username':headers?.username, 
//                 'session':headers?.sessionId,
//                 'responseType':"arraybuffer"
//             }})
//             .then((blob:any) => {
//                 // let data = `data:${blob.headers["content-type"] };base64,${new Buffer(blob.data, "binary").toString("base64")}`;
                
//                 // console.log("data")
//                 // console.log(blob.data)
//                 return Buffer.from(blob.data).toString('base64');
                
//                 // return data;
//             })
//             // .then((res:any) => res.blob())
//             .catch(reject)
//             .catch(function(e:any){
//                 if(e.code == "ERR_NETWORK" || e.response?.status == 401){
//                     dispatch(getAccessToken(headers?.accessToken))
//                     return {tokenExpire:true}
//                 }else{
//                     dispatch(setLoading(false));
//                     reject(e)
//                 }
//                 // dispatch(setLoading(false))
//             });
//         } catch (error) {
//             // dispatch(setLoading(false))
//             dispatch(setLoading(false));
//             reject(error);
//         }
//     });
// };

/**
 * Put request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const put = (path: string, params?: any, headers?: any) => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .put(path, params, { headers:{'Authorization': 'Bearer '+process.env.REACT_APP_ACCESS_TOKEN} })
                .then(function(getResponse:any){
                    return JSON.parse(getResponse.data)}
                )
                .then(resolve)
                .catch(reject);
        } catch (error) {
            reject(error);
        }
    });
};




export default { get, Post, put };
