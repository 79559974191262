import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from '../../redux/services/Api';
import { ReducerStateIF } from "../../redux/reducers";
import { toast } from 'react-toastify';
import { loginSuccessRequest } from "../../redux/actions/AuthAction";
import { alertMessages } from "../../config";
import css from './index.module.css';
import { setLoading } from "../../redux/actions/ConfigAction";
import ErrorPage from "../ErrorPage";

const ResetPassword = () => {
    const { token } = useParams()
    const [loader, setLoader] = useState(false);
    const dispatch:any = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state:ReducerStateIF) => state.app);

    useEffect(()=>{
        dispatch(setLoading(false));
    },[])

    const loginHandler = async(e:any) => {
        e.preventDefault()
        
        const password = (e.target.password.value).trim()
        const confirmPassword = (e.target.compassword.value).trim()

        if(confirmPassword && password && (confirmPassword == password)){
            setLoader(true)

            const data = {
                "token": token,
                "password": password,
                "confirmPassword": confirmPassword
            }

            const response:any = await API.Post("auth/admin/resetPassword", data, userDetails, dispatch)
            // console.log(response)
            if(response.status === "success"){
                e.target.reset()
                toast.success("Password updated successfully");
                navigate('/login')
            }else{
                toast.error(response.message)
            }
            setLoader(false)

        }else{
            toast.error("Password doesn't match")
        }

    }

    return(
        <>  
            {token ? (
                <div className="d-flex align-items-center login-page justify-content-center" style={{height: "100vh"}}>
                    <div className={css.loginContainer}>
                        <div className={css.text}>Set new password</div>
                        <form method="post" action="#" onSubmit={loginHandler} autoComplete="off">
                            <div className={css.field}>
                                <span className="fas fa-lock"></span>
                                <input type={"password"} name="password" className="form-control" placeholder=""/>
                                <label>New Password</label>
                            </div>
                            <div className={css.field}>
                                <span className="fas fa-lock"></span>
                                <input type={"password"} name="compassword" className="form-control" placeholder=""/>
                                <label>Confirm Password</label>
                            </div>
                            {/* <div className={css.forgotPass}>Forgot Password?</div> */}
                            <button className={css.signInButton}>Submit</button>
                        </form>
                    </div>
                </div>
            ):(
                <ErrorPage />
            )}
        </>
    );
}

export default ResetPassword;