import css from './index.module.css'
import {Col, Row} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useMemo, useState} from "react";
import android from "../../assets/icons8-android-96.png";
import apple from "../../assets/icons8-apple-96.png";
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import moment from 'moment';
import API from '../../redux/services/Api';
import DataTable from 'react-data-table-component';

const Feedback = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState(0)
    const [searchData, setSerachData] = useState({
        type:""
    })

    const columns = useMemo(
        () => [
          {
            name: "Time & Date",
            cell: (row:any) => row.timedate
          },
          {
            name: "User Name/Device ID.",
            cell: (row:any) => row.userid
          },
          {
            name: "Status",
            selector: (row:any) => row.status
          },
          {
            name: "Device",
            selector: (row:any) => row.device
          },
          {
            name: "Title",
            selector: (row:any) => row.title
          },
          {
            name: "Description",
            selector: (row:any) => row.description,
          }
        ],
        []
    );

    useEffect(() => {
        getUserData()
    },[searchData.type, currentPage, rowsPerPage, searchText]);
    
    const getUserData = async() => {

        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = { ...search, type: searchData.type }
        }

        const response:any = await API.Post("admin/feedbacks", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            const bodyData = tmpData.map((item:any, index:number) => {
                
                const statusButton = item.userId.status;
                const device = item.userId.devicetype === "android"?android:apple;

                var today = moment();
                var yesterday = moment().subtract(1, 'day');
                var createdAt = moment(item.createdAt).format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }

                return {
                    timedate: createdAt,
                    userid: item.userId.userid,
                    status: (<button type="button"
                                        className={`secondary rounded-5 border-0 py-1 px-3 ${statusButton === 'paid' ? css.paidButton : css.freeButton}`}>{statusButton}</button>),
                    device: (<img alt="" src={device} width="32px"/>),
                    title: item.title,
                    description: item.description
                }

            });

           setBody(bodyData)
           setTotalCount(response.data.total)
        }
    }
    
    const handleActiveButton = (buttonName: string) => {
        setSerachData(prevState => ({...prevState, type:buttonName}));
    };

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }

    return (
        <div className={`card p-3 ${css.tableContainer}`}>
            <Row className="mb-4 p-2">
                <Col xs={12} sm={12} md={6} lg={4}
                        className="d-flex flex-col align-items-center justify-content-between gap-3">
                    <div className={`btn-group ${css.tableButtons}`} role="group" aria-label="Basic example">
                        <button type="button" onClick={() => handleActiveButton('')}
                                style={{borderRadius: '50px 0 0 50px'}}
                                className={searchData.type === '' ? css.activeButton : css.allUserFilterButtons}>All
                            Users
                        </button>
                        <button type="button" onClick={() => handleActiveButton('free')}
                                className={searchData.type === 'free' ? css.activeButton : css.freeFilterButtons}>Free
                        </button>
                        <button type="button" onClick={() => handleActiveButton('paid')}
                                style={{borderRadius: '0 50px 50px 0'}}
                                className={searchData.type === 'paid' ? css.activeButton : css.paidFilterButtons}>Paid
                        </button>
                    </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={6} className="d-flex  search-input flex-col align-items-end">
                    <InputGroup className={css.searchInput}>
                        <InputGroup.Text id="basic-addon1"><FontAwesomeIcon
                            icon={faMagnifyingGlass}/></InputGroup.Text>
                        <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                    </InputGroup>
                </Col>
            </Row>
            <DataTable
                    className='feedbackTable'
                    pagination
                    paginationServer
                    // selectableRows
                    columns={columns}
                    data={body}
                    progressPending={loading}
                    paginationTotalRows={totalCount}
                    selectableRowsNoSelectAll={false}
                    paginationDefaultPage={currentPage}
                    paginationRowsPerPageOptions={[10,20,50,100]}
                    paginationPerPage={rowsPerPage}
                    // onRowMouseEnter={handleMouseEnter}
                    // onRowMouseLeave={handleMouseLeave}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    // onSelectedRowsChange={handleOnSelectedRowsChange}
                    // selectableRowSelected={handleApplySelectedRows}
                />
        </div>
    )
}

export default Feedback