import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import ErrorPage from "../Component/ErrorPage";
import Dashboard from "../Component/Dashboard";
import Template from "../Component/Layout/template";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from "../Component/User";
import Settings from "../Component/Settings";
import Feedback from "../Component/Feedback";
import Requests from "../Component/Requests";
import Completed from "../Component/Completed";
import Social from '../Component/Social';
import Examples from "../Component/Examples";
import UserDetail from "../Component/UserDetail";
import SocialView from "../Component/SocialView";
import Login from "../Component/Login";
import { ReducerStateIF } from "../redux/reducers";
import { useSelector } from "react-redux";
import Rejected from "../Component/Rejected";
import ResetPassword from "../Component/ResetPassword";
import Country from "../Component/Country";
import SocialExamples from "../Component/SocialExamples";
import SocialRequests from "../Component/SocialRequests";
import SocialCompleted from "../Component/SocialCompleted";
import DashboardExamples from "../Component/DashboardExamples";
import EditDashboardExamples from "../Component/DashboardExamples/editDashboard";


const UnProtectedRoute = ({redirectPath = '/' }:any) => {
    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    if (userDetails.username && userDetails.accessToken) {
        if (userDetails.username || userDetails.accessToken){ 
            return <Navigate to={redirectPath} replace />;
        }
    }
    return <Outlet />;
};

const ProtectedRoute = ({redirectPath = '/login' }:any) => {
    const location = useLocation();
    const userDetails = useSelector((state:ReducerStateIF) => state);

    if (!userDetails.app.username || !userDetails.app.accessToken) {
        return <Navigate to={redirectPath} replace />;        
    }
    return <Outlet />;
};

const AppMain = () => {
    return (
        <>
            <Suspense fallback={<span>loading</span>}>
                <Template>
                    <Routes>
                        <Route element={<UnProtectedRoute />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/resetpassword/:token" element={<ResetPassword />} />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/users">
                                <Route path="" element={<User />} />
                                <Route path=":id" element={<UserDetail />} />
                            </Route>
                            <Route path="/feedbacks" element={<Feedback/>} />
                            <Route path="/requests" element={<Requests/>} />
                            <Route path="/completed" element={<Completed/>} />
                            <Route path="/rejeted" element={<Rejected/>} />
                            <Route path="/examples" element={<Examples/>} />
                            <Route path="/dashboard-examples" element={<DashboardExamples/>} />
                            <Route path="/edit-dashboard-examples" element={<EditDashboardExamples/>} />
                            <Route path="/social" element={<Social/>} />
                            <Route path="/socialExample" element={<SocialExamples/>} />
                            <Route path="/social/viewall" element={<SocialView/>} />
                            <Route path="/countries" element={<Country/>} />
                            <Route path="/settings" element={<Settings/>} />

                            <Route path="/social_requests" element={<SocialRequests/>} />
                            <Route path="/social_completed" element={<SocialCompleted/>} />

                        </Route>
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </Template>
            </Suspense>
            <ToastContainer />
        </>
    );
};

export default AppMain;
