import css from './index.module.css';

const ErrorPage = () =>{

    return(
        <div className={css.errorDisply}>
            <p className={css.errorTitle}>Page Not Found</p>
        </div>
    );
}

export default ErrorPage;