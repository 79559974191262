import css from './index.module.css'

import {Col, Modal, Row} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import chevronsDown from '../../assets/chevrons-down.svg'
import {useEffect, useMemo, useState} from "react";
import android from "../../assets/icons8-android-96.png";
import apple from "../../assets/icons8-apple-96.png";
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

const SocialCompleted = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [imageModal, setImageModal] = useState(false)
    const initCount = {
        all: 0,
        free: 0,
        paid: 0
    }
    const [totalCount, setTotalCount] = useState<any>(initCount)
    const [searchData, setSerachData] = useState({
        type:""
    })
    const initEditData = {
        imageId: null,
        index: null,
        imageList: []
    }
    const [editData, setEditData] = useState<any>(initEditData)
    
    const handleActiveButton = (buttonName: string) => {
        setSerachData(prevState => ({...prevState, type:buttonName}));
    };

    // const headers: TableColumnType<UserData>[] = [
    //     {title: 'Time & Date', prop: 'timedate', isFilterable: true},
    //     {title: 'Status', prop: 'status'},
    //     {title: 'Device', prop: 'device'},
    //     {title: 'Sr No.', prop: 'srno'},
    //     {title: 'Images by Users', prop: 'userimg'},
    //     {title: 'Ai Generated', prop: 'aiimage'},
    //     {title: 'Action', prop: 'action'}
    // ];

    const columns = useMemo(
        () => [
          {
            name: "Time & Date",
            cell: (row:any) => row.timedate
          },
          {
            name: "Type",
            selector: (row:any) => row.status
          },
          {
            name: "Device",
            cell: (row:any) => row.device
          },
          {
            name: "Device Id",
            cell: (row:any) => row.deviceId
          },
          {
            name: "Location",
            cell: (row:any) => row.location
          },
          {
            name: "Sr No.",
            selector: (row:any) => row.srno
          },
          {
            name: "Images by Users",
            selector: (row:any) => row.userimg
          },
          {
            name: "Ai Generated",
            selector: (row:any) => row.aiimage
          },
          {
            name: "Request Status",
            selector: (row:any) => row.action
          }
        ],
        []
      );

    const getUserData = async() => {

        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = { ...search,  type: searchData.type }
        }

        const response:any = await API.Post("admin/social-sub-completed", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            const bodyData = tmpData.map((item:any, index:number) => {
                
                const statusButton = item.userId.status;
                const device = item.userId.devicetype === "android"?android:apple;
                const deviceID = item.userId.userid;
                var today = moment();
                var yesterday = moment().subtract(1, 'day');
                var createdAt = moment(item.createdAt).format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }

                return {
                    timedate: createdAt,
                    status: item.socialExampleId.page,
                    device: (<img alt="" src={device} width="32px"/>),
                    deviceId: (<Link className='user_view_link' to={"/users/"+item.userId._id}>{deviceID}</Link>),
                    location: item.userId.location,
                    srno:  "#"+((item.srno)?item.srno:0),
                    userimg: (
                        <div>
                            {/* {item.images.map((img:any, imgIndex:number) => ( */}
                            {((item.images.length > 4)?item.images.slice(0, 4):item.images).map((img:any, imgIndex:number) => (
                                <img key={"img_"+imgIndex} src={img} alt='' className={css.uploadImage}  loading="lazy"/>
                            ))}
                        </div>
                    ),
                    aiimage: (item.aiimages && item.aiimages.length > 0) ? (
                        <div className="d-flex align-items-center">
                            <img alt="" src={chevronsDown} className={`p-2 me-2 seletedArrowDown ${css.chevronsDown}`} onClick={() => viewImages(item._id,item.aiimages)}/>
                            {/* {item.aiimages.map((aiimg:string, aiIndex:number) => ( */}
                            {((item.aiimages.length > 4)?item.aiimages.slice(0, 4):item.aiimages).map((aiimg:string, aiIndex:number) => (
                                <img key={"img_"+aiIndex} src={aiimg} alt='' className={css.uploadImage} loading="lazy"/>
                            ))}
                        </div>
                    ) : '-',
                    action: (
                        <div  className={`${item.status === "submited" ? css.approveButton : css.rejectedButton}`}>{item.status}</div>
                    ),
                }

            });
           setBody(bodyData)
           if(response.data.total.length > 0){
                delete response.data.total[0]._id;
                setTotalCount(response.data.total[0])
            }else{
                setTotalCount(initCount)
            }
        }
    }

    useEffect(() => {
        getUserData()
    },[searchData.type, currentPage, rowsPerPage, searchText]);

    const viewImages = (imageId:string, imgList:any) => {
        setEditData((prevState: any) => ({...prevState, imageId:imageId, imageList: imgList}));
        setImageModal(true)
    }

    const closeImageModal = () => {
        setImageModal(false)
        setEditData(initEditData);
    }

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }
    
    return (
        <>
            <div className={`card p-3 ${css.tableContainer}`}>
                {/* <DatatableWrapper
                    body={body}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [10, 20, 50, 100]
                        }
                    }}
                > */}
                    <Row className="mb-4 p-2 justify-content-center">
                        {/* <Col xs={12} sm={12} md={12} lg={6}
                            className="d-flex flex-col align-items-center justify-content-between gap-3">
                            <div className={`btn-group ${css.tableButtons}`} role="group">
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === '' ? css.activeButton : css.allUserFilterButtons}`}
                                        onClick={() => handleActiveButton('')}
                                        style={{borderRadius: '50px 0 0 50px'}}>All Users</button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'free' ? css.activeButton : css.freeFilterButtons}`}
                                        onClick={() => handleActiveButton('free')}>Free</button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'paid' ? css.activeButton : css.paidFilterButtons}`}
                                        style={{borderRadius: '0 50px 50px 0'}}
                                        onClick={() => handleActiveButton('paid')}>Paid</button>
                            </div>
                        </Col> */}
                        <Col xs={12} sm={6} md={4} lg={4} className='d-flex search-input text-center'>
                            <InputGroup className={css.searchInput}>
                                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon
                                    icon={faMagnifyingGlass}/></InputGroup.Text>
                                {/* <Form.Control placeholder="Search" aria-label="Search"/> */}
                                <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                            </InputGroup>
                        </Col>
                    </Row>
                    <DataTable
                        className='completeImg'
                        pagination
                        paginationServer
                        // selectableRows
                        columns={columns}
                        data={body}
                        progressPending={loading}
                        paginationTotalRows={totalCount[searchData.type == ""?"all":searchData.type]}
                        selectableRowsNoSelectAll={false}
                        paginationDefaultPage={currentPage}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        paginationPerPage={rowsPerPage}
                        // onRowMouseEnter={handleMouseEnter}
                        // onRowMouseLeave={handleMouseLeave}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        // onSelectedRowsChange={handleOnSelectedRowsChange}
                        // selectableRowSelected={handleApplySelectedRows}
                    />
                {/* </DatatableWrapper> */}
            </div>
            <Modal show={imageModal} onHide={closeImageModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Upload Images</h4>
                    <button type='button' className='close-btn m-3' onClick={closeImageModal}>Close</button>
                </div>
                <div className="modal_body image-grid-view d-inline-flex p-3">
                        {editData.imageList.map((img:string, imgIndex:number) => (
                            <div className='editView' key={"view_"+imgIndex}>
                                <img key={imgIndex} src={img} alt='' className={css.footerImage} loading="lazy"/>
                            </div>
                        ))}
                </div>
            </Modal>
        </>
    )
}

export default SocialCompleted