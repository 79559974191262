import * as types from './types';

const loadingRequest = (payload:any) => {
    return {
        type: types.LOADING,
        payload: payload,
    };
}
  
export const setLoading = (loader: boolean) => {
    return async (dispatch:any) => {
        dispatch(loadingRequest({loader:loader}));
    };
};


const drawerRequest = (payload:any) => {
    return {
        type: types.DRAWER,
        payload: payload,
    };
  }
  
  export const drawerAction = (drawer: boolean) => {
    return async (dispatch:any) => {
        dispatch(drawerRequest({drawer:drawer}));
    };
  };


// export const getAccessToken = (oldkey:string) => {
//     return async (dispatch:any) => {
//         const tokenApi = (process.env.REACT_APP_TOKEN_API)?process.env.REACT_APP_TOKEN_API:""
//         var basicAuth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);
//         // console.log(basicAuth);
//         axios.post(tokenApi, {accessToken:oldkey})
//           .then(function(data:any){
//               dispatch(updateAccessToken({accessToken:data.data.accessToken, baseApiUri:data.data.baseUri}));
//           })
//           .catch(function(e:any){
//               if(e.code == "ERR_NETWORK"){
//                   dispatch(logout())
//                   toast.error(e.message)
//               }
//           });
  
//     };
//   }