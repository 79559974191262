import * as types from '../actions/types';

export interface confStateIF {
    loader: boolean;
    drawer: boolean;
}

export const initialState: confStateIF = {
    loader: true,
    drawer: false
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, ...action.payload };
        case types.LOADING:
            return { 
                    ...state,
                    loader: action.payload.loader 
                };
        case types.DRAWER:
            return { 
                    ...state,
                    drawer: action.payload.drawer 
                };
        default:
            return state;
    }
};

export default reducer;
