import css from "./style.module.css";
import {Layout, Menu} from 'antd';
import {Link} from "react-router-dom";
import logo from "../../assets/logo.png";
import vector from "../../assets/Vector.svg";
import users from "../../assets/users.svg";
import checks from "../../assets/checks.svg";
import rejected from "../../assets/rejected.svg";
import message from "../../assets/message-2-bolt.svg";
import photo from "../../assets/photo.svg";
import request from "../../assets/Request.svg";
import phone from "../../assets/speakerphone.svg"
import settings from "../../assets/settings.svg"
import { ReducerStateIF } from "../../redux/reducers";
import { useSelector } from "react-redux";

const {Sider} = Layout;

const Sidebar = () => {

    const isOpen = useSelector((state:ReducerStateIF) => state.conf.drawer);
    
    const menuItems = [
        {
            label: "Dashboard",
            icon: vector,
            href: './'
        },
        {
            label: "Users",
            icon: users,
            href: "/users"
        },
        {
            label: "Requests",
            icon: request,
            href: "/requests"
        },
        {
            label: "Completed",
            icon: checks,
            href: "/completed"
        },
        {
            label: "Rejeted",
            icon: rejected,
            href: "/rejeted"
        },
        {
            label: "Social Example",
            icon: photo,
            href: "/socialExample"
        },
        {
            label: "Social Requests",
            icon: request,
            href: "/social_requests"
        },
        {
            label: "Social Completed",
            icon: checks,
            href: "/social_completed"
        },
        {
            label: "Examples",
            icon: photo,
            href: "/examples"
        },
        {
            label: "Prompt",
            icon: photo,
            href: "/dashboard-examples"
        },
        {
            label: "Social",
            icon: phone,
            href: "/social"
        },
        {
            label: "Feedbacks",
            icon: message,
            href: "/feedbacks"
        },
        {
            label: "Countries",
            icon: message,
            href: "/countries"
        },
        {
            label: "Settings",
            icon: settings,
            href: "/settings"
        },
        // {
        //     label: "User Details",
        //     icon: settings,
        //     href: "/user-detail"
        // },
        // {
        //     label: "Social View",
        //     icon: settings,
        //     href: "/social-view"
        // }
    ]

    return (
        <>
            <Sider
                breakpoint="xl"
                collapsedWidth="50"
                onBreakpoint={broken => {}}
                // onCollapse={(collapsed, type) => {setOpen(collapsed)}}
                collapsed={isOpen}
                className={css.sidebar_main}>
                <div className="text-center">
                    <Link to={"/"}><img alt='' src={logo} className="pt-4 logoImg"/></Link>
                </div>
                <div className={css.sidebarmenu_main}>
                    <div className={css.sidebar_scroll}>
                        <Menu mode="inline" className={css.menuItemSidebar}>
                            {menuItems.map((menuItem, index) => (
                                <Menu.Item key={index} className={css.client_refer}>
                                    <Link to={menuItem.href}><img alt="" src={menuItem.icon} className={css.menuIcon}/>{menuItem.label}</Link>
                                </Menu.Item>
                        ))}
                        </Menu>
                    </div>
                </div>
            </Sider>
        </>
    );
}

export default Sidebar;