import {Layout} from 'antd';
import Sidebar from "./sidebar";
import Header from "./header";
import css from './style.module.css'
import { ReducerStateIF } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Template = ({children}: any) => {
    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const loader = useSelector((state:ReducerStateIF) => state.conf.loader);
    return (
        <Layout>
                
            {loader && (<div className='loader_screen'><span className='loader'></span></div>)}
            {(userDetails.username && userDetails.accessToken && userDetails.username !='' && userDetails.accessToken != '') && (<Sidebar/>)}
            <Layout>
                {(userDetails.username && userDetails.accessToken && userDetails.username !='' && userDetails.accessToken != '') && (<Header/>)}
                <div className={`${(userDetails.username && userDetails.accessToken && userDetails.username !='' && userDetails.accessToken != '') ?css.layoutBody:css.fullLayoutBody} p-4`}>{children}</div>
            </Layout>
        </Layout>
    );
}

export default Template;