import axios from 'axios';
import * as types from './types';
import { setLoading } from './ConfigAction';

export const loginSuccessRequest = (payload:any) => {
    return {
        type: types.LOGIN_SUCCESS,
        payload: payload,
    };
}

const logoutRequest = () => {
    return {
        type: types.LOGOUT,
        payload: {},
    };
}



export const loginSuccess = (data:any) => {
  return async (dispatch:any) => {
    dispatch(loginSuccessRequest(data));
  };
};

export const logout = () => {
  return async (dispatch:any) => {
      localStorage.clear()
      dispatch(logoutRequest());
  };
};


const updateAccessToken = (payload:any) => {
  return {
      type: "SECRET_UPDATE",
      payload: payload,
  };
  
}

// export const getAccessToken = (oldkey:string) => {
//   return async (dispatch:any) => {
//       const tokenApi = (process.env.REACT_APP_TOKEN_API)?process.env.REACT_APP_TOKEN_API:""
//       var basicAuth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);
//       console.log(basicAuth);
//       axios.post(tokenApi, {accessToken:(oldkey)?oldkey:"0"}, { 
//         headers:{
//           'Content-Type': 'application/x-www-form-urlencoded', 
//           "App-Secret-Key": process.env.REACT_APP_API_SECRET, 
//           "Authorization-Token":process.env.REACT_APP_AUTH_TOKEN, 
//           "Authorization":basicAuth
//         }})
//         .then(function(data:any){
//             dispatch(updateAccessToken({accessToken:data.data.accessToken}));
//         })
//         .catch(function(e:any){
//             if(e.code == "ERR_NETWORK"){
//                 dispatch(logout())
//                 toast.error(e.message)
//             }
//         });

//   };
// }

export const validateAccessToken = (states:any) => {
  return async (dispatch:any) => {
    if(states.refreshToken && states.accessToken){
        const apiPAth = process.env.REACT_APP_API_URI+"auth/refresh-token"
          axios.post(apiPAth, {}, { headers:{
              'Access-Control-Allow-Origin':'*',
              'Content-Type': 'application/json', 
              'Authorization': "Bearer "+states.refreshToken, 
          }})
          .then(function(data:any){
              // console.log(data)
              if(data.data.result_code != 0){
                  dispatch(logout())
                  dispatch(setLoading(false));
              }else{
                  dispatch(loginSuccessRequest({
                      ...states,
                      accessToken: data.data.result.data.accessToken,
                      refreshToken: data.data.result.data.refreshToken,
                      permissions: data.data.result.data.permissions
                  }));
                  // window.location.href = '/'
              }
          })
          .catch(function(e:any){
              dispatch(logout())
              dispatch(setLoading(false));
          })
    }
  };
}