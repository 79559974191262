import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from '../../redux/services/Api';
import { ReducerStateIF } from "../../redux/reducers";
import { toast } from 'react-toastify';
import { loginSuccessRequest } from "../../redux/actions/AuthAction";
import { alertMessages } from "../../config";
import css from './index.module.css';
import { setLoading } from "../../redux/actions/ConfigAction";
import { Modal } from "react-bootstrap";

const Login = () => {

    const [loader, setLoader] = useState(false);
    const dispatch:any = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state:ReducerStateIF) => state.app);

    const [viewPassword, setViewPassword] = useState(false)

    const changePasswordView = () => {
        if(viewPassword){
            setViewPassword(false)
        }else{
            setViewPassword(true)
        }
    }

    useEffect(()=>{
        dispatch(setLoading(false));
    },[])

    const loginHandler = async(e:any) => {
        e.preventDefault()
        
        const username = (e.target.email.value).toLowerCase()
        const password = (e.target.password.value).toLowerCase()

        if(username && password){
            setLoader(true)

            const data = {
                "email": username,
                "password": password,
            }

            const response:any = await API.Post("auth/admin/login", data, userDetails, dispatch)
            // console.log(response)
            if(response.status === "success"){
                e.target.reset()
                dispatch(loginSuccessRequest({
                    username: response.data.username,
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token,
                }));
                navigate('/')

            }else{
                toast.error(response.message)
            }
            setLoader(false)

        }else{
            toast.error(alertMessages.loginValidationError)
        }

    }

    const [forgotModal, setForgotModal] = useState(false);

    const openForgotModal = () => {
        setForgotModal(true)
    }

    const closeForgotModal = () => {
        setForgotModal(false)
    }


    const forgotAction = async(e:any) => {
        e.preventDefault()
        
        const email = (e.target.email.value).toLowerCase()

        if(email){
            setLoader(true)

            const data = {
                "email": email
            }

            const response:any = await API.Post("auth/admin/forgotPassword", data, userDetails, dispatch)
            if(response.status === "success"){
                e.target.reset()
                closeForgotModal()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }
            setLoader(false)

        }else{
            toast.error(alertMessages.loginValidationError)
        }

    }


    return(
        <>
             <div className="d-flex align-items-center login-page justify-content-center" style={{height: "100vh"}}>
                <div className={css.loginContainer}>
                    <div className={css.text}>Login</div>
                    <form method="post" action="#" onSubmit={loginHandler} autoComplete="off">
                        <div className={css.field}>
                            <span className="fas fa-user"></span>
                            <input type={"text"} name="email" className="form-control" placeholder="Email" />
                        </div>
                        <div className={css.field}>
                            <span className="fas fa-lock"></span>
                            <input type={viewPassword?"text":"password"} name="password" className="form-control" placeholder="Password"/>
                            <label>Password</label>
                        </div>
                        <div className={css.forgotPass} onClick={openForgotModal}>Forgot Password?</div>
                        <button className={css.signInButton}>Submit</button>
                    </form>
                </div>
            </div>
            <Modal show={forgotModal} onHide={closeForgotModal} size='lg' aria-labelledby="contained-modal-title-vcenter" centered >
                <div className='modal_header d-flex justify-content-between align-items-center'>
                    <h4 className="text-center m-4">Forgot Password</h4>
                    <button type='button' className='close-btn m-3' onClick={closeForgotModal}>Close</button>
                </div>
                <div className="modal_body p-3">
                    <form method="post" action="#" onSubmit={forgotAction} autoComplete="off">
                        <div className={css.field}>
                            <span className="fas fa-user"></span>
                            <input type={"text"} name="email" className="form-control h-50" placeholder="Email" />
                        </div>
                        <button className={css.signInButton} type="submit">Submit</button>
                    </form>
                </div>
            </Modal>
        </>
    );
}

export default Login;