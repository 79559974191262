import {Col,Row} from "react-bootstrap";
import total_user from "../../assets/total-user-icon.png";
import free_user from "../../assets/free-user-icon.png";
import paid_user from "../../assets/paid-user-icon.png";
import css from "./index.module.css";
import androidReferrals from '../../assets/androidReferrals.svg';
import circleIcon from '../../assets/CircleIconBagde.svg';
import iosReferrals from '../../assets/iosReferrals.svg';
import { useEffect, useState } from "react";
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateIF } from "../../redux/reducers";

const Dashboard = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();

    const [data, setData] = useState({
        users:0,
        freeUsers:0,
        paidUsers:0,
        androidTotalUsers:0,
        androidFreeUsers:0,
        androidPaidUsers:0,
        iosTotalUsers:0,
        iosFreeUsers:0,
        iosPaidUsers:0,
        referTotalUsers:0,
        referAndroidUsers:0,
        referIosUsers:0
    })

    useEffect(() => {
        (async () => {
            const response:any = await API.Post("admin/dashboard", {}, userDetails, dispatch)
            if(response.status === "success" && response.data){
                    const tmpData = response.data;
                    setData({
                        users: tmpData.totalUser,
                        freeUsers: tmpData.totalFreeUser,
                        paidUsers: tmpData.totalPaidUser,
                        androidTotalUsers: tmpData.totalAndroidUser,
                        androidFreeUsers: tmpData.totalAndroidFreeUser,
                        androidPaidUsers: tmpData.totalAndroidPaidUser,
                        iosTotalUsers: tmpData.totalAppleUser,
                        iosFreeUsers: tmpData.totalAppleFreeUser,
                        iosPaidUsers: tmpData.totalApplePaidUser,
                        referTotalUsers: tmpData.totalReferUser,
                        referAndroidUsers: tmpData.totalReferAndroidUser,
                        referIosUsers: tmpData.totalReferAppleUser
                    })
            }
        })()    
    },[]);
    
    return(
        <>
            <div>
                <h5 className="mb-4 text-start">Users Stats</h5>
                <Row>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={total_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Total Users</div>
                                <div className={css.count}>{data.users}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={free_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Total Free Users</div>
                                <div className={css.count}>{data.freeUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={paid_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Total Paid Users</div>
                                <div className={css.count}>{data.paidUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={androidReferrals} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Android Users</div>
                                <div className={css.count}>{data.androidTotalUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={free_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Free Users</div>
                                <div className={css.count}>{data.androidFreeUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={paid_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Paid Users</div>
                                <div className={css.count}>{data.androidPaidUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={iosReferrals} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>iOS Users</div>
                                <div className={css.count}>{data.iosTotalUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={free_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Free Users</div>
                                <div className={css.count}>{data.iosFreeUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={paid_user} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Paid Users</div>
                                <div className={css.count}>{data.iosPaidUsers}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <h5 className="mb-4 mt-4 text-start">Refer Stats</h5>
                <Row>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={circleIcon} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Total Users</div>
                                <div className={css.count}>{data.referTotalUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={androidReferrals} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>Android Users</div>
                                <div className={css.count}>{data.referAndroidUsers}</div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6} md={4}>
                        <div className="card d-flex flex-row gap-2 p-3 mb-3">
                            <img alt='' src={iosReferrals} height="40px" width="40px"/>
                            <div className="text-start">
                                <div className={css.title}>IOS Users</div>
                                <div className={css.count}>{data.referIosUsers}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Dashboard;