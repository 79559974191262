import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import css from './index.module.css';
import { useEffect, useState } from 'react';
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import { toast } from 'react-toastify';
import { faEdit, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Settings = () => {

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    
    const initData = {
        setting: {
            screenshot: false,
            daily_free_credit: 10,
            user_limit : {
                photo_upload_min_limit:0,
                photo_upload_max_limit:0,
                daily_download_limit:0
            },
            generated_photo:{
                free:0,
                rewarded:0
            },
            time_gap:{
                photo_notification: 0,
                generated_photo_gap: 0
            }
        },
        advertisement:{
            allAdsStatus: false,
            appOpen: false,
            bannerAds: false,
            interstitial: false,
            native: false,
            reward: false,
            appOpenId:"",
            bannerAdsId: "",
            interstitialId: "",
            nativeId: "",
            rewardId: "",
            appOpenIdIOS:"",
            bannerAdsIdIOS: "",
            interstitialIdIOS: "",
            nativeIdIOS: "",
            rewardIdIOS: ""
        },
        onBording: {
            title1: "",
            desc1: "",
            text1: "",
            filename1: "",
            type1: "",
            onbodingFile1: null,
            onbodingFile1Type: null,
            title2: "",
            desc2: "",
            text2: "",
            filename2: "",
            type2: "",
            onbodingFile2: null,
            onbodingFile2Type: null
        },
        purchase_files:{
            purchaseFile1: null,
            purchaseFile1Type: null,
            purchaseFile2: null,
            purchaseFile2Type: null,
            purchaseFile3: null,
            purchaseFile3Type: null
        },
        setting_page_files: {
            settingPageFile1: null,
            settingPageFile1Type: null,
            settingPageFile2: null,
            settingPageFile2Type: null,
            settingPageFile3: null,
            settingPageFile3Type: null
        },
        subscription_pack: []
    }

    const [settingData, setSettingData] = useState(initData.setting)
    const [advertisementData, setAdvertisementData] = useState<any>(initData.advertisement)
    const [onBordingData, setOnBordingData] = useState<any>(initData.onBording)
    const [purchaseData, setPurchaseData] = useState<any>(initData.purchase_files)
    const [settingFilesData, setSettingFilesData] = useState<any>(initData.setting_page_files)
    const [subscriptionPack, setSubscriptionPack] = useState<any>(initData.subscription_pack)

    const getSettingData = async() => {
        const response:any = await API.Post("admin/settings", {}, userDetails, dispatch)
        if(response.status === "success"){

            setSettingData(response.data.setting ?? initData.setting)
            setAdvertisementData(response.data.advertisement ?? initData.advertisement)
            let tmpOnboardData:any = initData.onBording
            if(response.data.onBording){
                tmpOnboardData = {
                                    title1: response.data.onBording[0].title,
                                    desc1: response.data.onBording[0].desc,
                                    text1: response.data.onBording[0].text,
                                    filename1: response.data.onBording[0].filename,
                                    type1: response.data.onBording[0].type,
                                    onbodingFile1: null,
                                    onbodingFile1Type: null,
                                    title2: response.data.onBording[1].title,
                                    desc2: response.data.onBording[1].desc,
                                    text2: response.data.onBording[1].text,
                                    filename2: response.data.onBording[1].filename,
                                    type2: response.data.onBording[1].type,
                                    onbodingFile2: null,
                                    onbodingFile2Type: null
                                }
            }
            setOnBordingData(tmpOnboardData)

            let tmpPurcaseData:any = initData.purchase_files
            if(response.data.purchaseFiles){
                response.data.purchaseFiles.map((files:any, i:number) => {
                    tmpPurcaseData["purchaseFile"+(i+1)] =  files.filename? files.filename:null
                    tmpPurcaseData["purchaseFile"+(i+1)+"Type"] =  files.type? files.type:null
                })
            }
            setPurchaseData(tmpPurcaseData)


            let tmpPageFiles:any = initData.setting_page_files
            if(response.data.pageFiles){
                response.data.pageFiles.map((files:any, i:number) => {
                    tmpPageFiles["settingPageFile"+(i+1)] =  files.filename? files.filename:null
                    tmpPageFiles["settingPageFile"+(i+1)+"Type"] =  files.type? files.type:null
                })
            }
            setSettingFilesData(tmpPageFiles)

            setSubscriptionPack(response.data.subscription_pack ?? initData.subscription_pack)

        }else{
            toast.error(response.message)
        }
    }

    useEffect(() => {
        getSettingData()
    },[])

    const handleSetting =async (e:any, type?:string) => {
        if(type){
            setSettingData((prevData:any) => ({
                ...prevData,
                [type]:{
                    ...prevData[type],
                    [e.target.name]: Number(e.target.value)
                }
            }))
        }else{
            setSettingData((prevData:any) => ({
                ...prevData,
                [e.target.name]: Number(e.target.value)
            }))
        }
    }

    const udpateSetting = async(e:any) => {
        e.preventDefault()
        try {
            let data = {
                keyname: "settings",
                keyvalue: settingData
            }
            const response:any = await API.Post("admin/setting-handler", data, userDetails, dispatch)
            if(response.status === "success"){
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleAdvertisement =async (e:any) => {
        setAdvertisementData((prevData:any) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }

    const udpateAdvertisement = async(e:any) => {
        e.preventDefault()
        try {
            let data = {
                keyname: "advertisement",
                keyvalue: advertisementData
            }
            const response:any = await API.Post("admin/setting-handler", data, userDetails, dispatch)
            if(response.status === "success"){
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleOnBoard = async (e:any) => {
        setOnBordingData((prevData:any) => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }

    const handleOnBoardImage = (e:any) => {
        let type = e.target.files[0]["type"].split("/")
        setOnBordingData((prevData:any) =>({
            ...prevData, 
            [e.target.name]: e.target.files[0],
            [e.target.name+"Type"]: type[0]
        }));
    }

    const udpateOnBoard = async(e:any) => {
        e.preventDefault()
        try {

            const formetData = [
                {
                    title: onBordingData.title1,
                    desc: onBordingData.desc1,
                    text: onBordingData.text1,
                    filename: onBordingData.filename1,
                    type: onBordingData.type1,
                },
                {
                    title: onBordingData.title2,
                    desc: onBordingData.desc2,
                    text: onBordingData.text2,
                    filename: onBordingData.filename2,
                    type: onBordingData.type2
                }
            ]

            let formdata = new FormData(e.target)
            formdata.append("keyname", "onbording");
            formdata.append("keyvalue", JSON.stringify(formetData));
            
            const response:any = await API.Post("admin/setting-handler", formdata, userDetails, dispatch, true)
            if(response.status === "success"){
                e.target.reset()
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handlePurchaseImage = (e:any) => {
        let type = e.target.files[0]["type"].split("/")
        setPurchaseData((prevData:any) =>({
            ...prevData, 
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
            [e.target.name+"Type"]: type[0]
        }));
    }

    const updatePurchase = async(e:any) => {
        e.preventDefault()
        try {

            let formdata = new FormData(e.target)
            formdata.append("keyname", "purchase_files");
            
            const response:any = await API.Post("admin/setting-handler", formdata, userDetails, dispatch, true)
            if(response.status === "success"){
                e.target.reset()
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleSettingImage = (e:any) => {
        let type = e.target.files[0]["type"].split("/")
        setSettingFilesData((prevData:any) =>({
            ...prevData, 
            [e.target.name]: URL.createObjectURL(e.target.files[0]),
            [e.target.name+"Type"]: type[0]
        }));
    }

    const updateSettingPage = async(e:any) => {
        e.preventDefault()
        try {

            let formdata = new FormData(e.target)
            formdata.append("keyname", "setting_page_files");
            
            const response:any = await API.Post("admin/setting-handler", formdata, userDetails, dispatch, true)
            if(response.status === "success"){
                e.target.reset()
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleChangeAdsSwitch = async(e:any) => {
        setAdvertisementData((prevData:any) => ({
            ...prevData,
            [e.target.name]: (prevData[e.target.name])?false:true
        }))
    }

    const handleChangeSettingSwitch = async(e:any) => {
        setSettingData((prevData:any) => ({
            ...prevData,
            [e.target.name]: (prevData[e.target.name])?false:true
        }))
    }

    const initSubEditData = {
        index: null,
        subId: null,
        limit: null
    };

    const [subscriptionEditData, setSubscriptionEditData] = useState<any>(initSubEditData);

    const editSubscription = (index:number, data:any) =>{
        setSubscriptionEditData({
            index: index,
            subId: data.subId,
            limit: data.limit
        })
    }

    const handleSubscription = (e:any) => {
        setSubscriptionEditData((oldData:any) => ({
            ...oldData,
            [e.target.name]: e.target.value
        }))
    }

    const updateSubscriptionPack = async(data:any) => {
        try {
            let updatedata = {
                keyname: "subscription_pack",
                keyvalue: data
            }
            const response:any = await API.Post("admin/setting-handler", updatedata, userDetails, dispatch)
            if(response.status === "success"){
                setSubscriptionEditData(initSubEditData)
                getSettingData()
                toast.success(response.message)
            }else{
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const editSubscriptionUpdate = (e:any) => {
        e.preventDefault()
        let tmpsubscriptionPack = subscriptionPack;
        if(subscriptionEditData.index !== null){
            tmpsubscriptionPack[subscriptionEditData.index] = {
                subId: subscriptionEditData.subId,
                limit: subscriptionEditData.limit
            }
        }else{
            tmpsubscriptionPack.push({
                subId: subscriptionEditData.subId,
                limit: subscriptionEditData.limit
            })
        }
        setSubscriptionPack((oldState:any) => tmpsubscriptionPack);
        updateSubscriptionPack(tmpsubscriptionPack);
    }

    const deleteSubscription = (index:number) => {
        let tmpsubscriptionPack = subscriptionPack;
        tmpsubscriptionPack.splice(index, 1);
        setSubscriptionPack((oldState:any) => tmpsubscriptionPack);
        updateSubscriptionPack(tmpsubscriptionPack)
    }


    return(
        <>
            <Form className="card p-4 mb-4" method='post' onSubmit={udpateSetting}>
                <div className={`d-flex align-items-center justify-content-between ${css.formContainer}`}>
                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Settings</h5>
                    <Button className="rounded-5" type="submit">Save</Button>
                </div>
                <Row>
                    <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                        <Form.Label className={css.label}>Daily Free Credit</Form.Label>
                        <Form.Control type="number" name="daily_free_credit" value={settingData.daily_free_credit} onChange={handleSetting} required/>
                    </Form.Group>

                    <h5 className={`mb-3 text-start ${css.formTitle}`}>User Limits</h5>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicEmail">
                        <Form.Label className={css.label}>Photo Upload Limit</Form.Label>
                        <div className="d-flex gap-1">
                            <Form.Control type="number" name="photo_upload_min_limit" value={settingData.user_limit.photo_upload_min_limit} onChange={(e) => handleSetting(e, "user_limit")}  placeholder="Min" required/>
                            <Form.Control type="number" name="photo_upload_max_limit" value={settingData.user_limit.photo_upload_max_limit} onChange={(e) => handleSetting(e, "user_limit")}  placeholder="Max" required/>
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicPassword">
                        <Form.Label className={css.label}>Daily Generate Limit</Form.Label>
                        <Form.Control type="number" name="daily_download_limit" value={settingData.user_limit.daily_download_limit}  onChange={(e) => handleSetting(e, "user_limit")}  required/>
                    </Form.Group>

                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Generated Photo</h5>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicEmail">
                        <Form.Label className={css.label}>FREE</Form.Label>
                        <Form.Control type="number" name="free" value={settingData.generated_photo.free} onChange={(e) => handleSetting(e, "generated_photo")}  required/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicPassword">
                        <Form.Label className={css.label}>Rewarded</Form.Label>
                        <Form.Control type="number" name="rewarded" value={settingData.generated_photo.rewarded}  onChange={(e) => handleSetting(e, "generated_photo")}  required/>
                    </Form.Group>

                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Time Gap</h5>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicEmail">
                        <Form.Label className={css.label}>Generated Photo Notification - Pending Downloads</Form.Label>
                        <Form.Control type="number" name="photo_notification" value={settingData.time_gap.photo_notification}  onChange={(e) => handleSetting(e, "time_gap")}   required/>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-3 text-start" controlId="formBasicPassword">
                        <Form.Label className={css.label}>Gap Between Generate Photo (After Generate Button
                            Click)</Form.Label>
                        <Form.Control type="number" name="generated_photo_gap"  value={settingData.time_gap.generated_photo_gap}  onChange={(e) => handleSetting(e, "time_gap")}  required/>
                    </Form.Group>
                    <h5 className={`mb-3 d-flex mt-5 text-start ${css.formTitle}`}>Screenshot enable 
                    <Form.Check
                            type="switch"
                            name='screenshot'
                            className='m-2 mt-0'
                            checked={settingData.screenshot}
                            onChange={handleChangeSettingSwitch}
                        /></h5>
                </Row>
            </Form>
            <Form className="card p-4 mb-4 advertisementSec" method='post' onSubmit={udpateAdvertisement}>
                <div className={`d-flex align-items-center justify-content-between ${css.formContainer}`}>
                    <h5 className={`mb-3 d-flex text-start ${css.formTitle}`}>
                        Advertisement IDs
                        <Form.Check
                            type="switch"
                            name='allAdsStatus'
                            className='m-2 mt-0'
                            checked={advertisementData.allAdsStatus}
                            onChange={handleChangeAdsSwitch}
                        />
                    </h5>
                    <Button type="submit" className={`rounded-5 ${css.saveButton}`}>Save</Button>
                </div>
                <Row>
                    <Form.Group as={Col} md="6" className="mb-4 text-start" controlId="formBasicEmail">
                        <Form.Label className={`d-flex m-0 ${css.label}`}>
                            App Open
                            <Form.Check
                                type="switch"
                                name='appOpen'
                                className='m-2 mt-0'
                                checked={advertisementData.appOpen}
                                onChange={handleChangeAdsSwitch}
                            />
                        </Form.Label>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> Android </Form.Label>
                                <Form.Control type="text" name="appOpenId" value={advertisementData.appOpenId} onChange={handleAdvertisement}/>
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> IOS </Form.Label>
                                <Form.Control type="text" name="appOpenIdIOS" value={advertisementData.appOpenIdIOS} onChange={handleAdvertisement}/>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4 text-start" controlId="formBasicPassword">
                        <Form.Label className={`d-flex m-0 ${css.label}`}>
                            Banner Ads
                            <Form.Check
                                type="switch"
                                name='bannerAds'
                                className='m-2 mt-0'
                                checked={advertisementData.bannerAds}
                                onChange={handleChangeAdsSwitch}
                            />
                        </Form.Label>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> Android </Form.Label>
                                <Form.Control type="text" name="bannerAdsId" value={advertisementData.bannerAdsId} onChange={handleAdvertisement} />
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> IOS </Form.Label>
                                <Form.Control type="text" name="bannerAdsIdIOS" value={advertisementData.bannerAdsIdIOS} onChange={handleAdvertisement} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4 text-start" controlId="formBasicEmail">
                        <Form.Label className={`d-flex m-0 ${css.label}`}>
                            Interstitial
                            <Form.Check
                                type="switch"
                                name='interstitial'
                                className='m-2 mt-0'
                                checked={advertisementData.interstitial}
                                onChange={handleChangeAdsSwitch}
                            />
                        </Form.Label>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> Android </Form.Label>
                                <Form.Control type="text" name="interstitialId" value={advertisementData.interstitialId} onChange={handleAdvertisement}/>
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> IOS </Form.Label>
                                <Form.Control type="text" name="interstitialIdIOS" value={advertisementData.interstitialIdIOS} onChange={handleAdvertisement}/>
                            </Col>
                        </Row>
                        
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4 text-start" controlId="formBasicPassword">
                        <Form.Label className={`d-flex m-0 ${css.label}`}>
                            Native
                            <Form.Check
                                type="switch"
                                name='native'
                                className='m-2 mt-0'
                                checked={advertisementData.native}
                                onChange={handleChangeAdsSwitch}
                            />
                        </Form.Label>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> Android </Form.Label>
                                <Form.Control type="text" name="nativeId" value={advertisementData.nativeId} onChange={handleAdvertisement} />
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> IOS </Form.Label>
                                <Form.Control type="text" name="nativeIdIOS" value={advertisementData.nativeIdIOS} onChange={handleAdvertisement} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-4 text-start" controlId="formBasicEmail">
                        <Form.Label className={`d-flex m-0 ${css.label}`}>
                            Reward
                            <Form.Check
                                type="switch"
                                name='reward'
                                className='m-2 mt-0'
                                checked={advertisementData.reward}
                                onChange={handleChangeAdsSwitch}
                            />
                        </Form.Label>
                        <Row>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> Android </Form.Label>
                                <Form.Control type="text" name="rewardId" value={advertisementData.rewardId} onChange={handleAdvertisement}/>
                            </Col>
                            <Col sm={6}>
                                <Form.Label className={`m-0 ${css.label}`}> IOS </Form.Label>
                                <Form.Control type="text" name="rewardIdIOS" value={advertisementData.rewardIdIOS} onChange={handleAdvertisement}/>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row>
            </Form>

            <div className='card p-4 mb-4'>
                <div className={`${css.formContainer}`}>
                    <h5 className={`mb-3 d-flex text-start ${css.formTitle}`}>
                        Subscription Packs
                    </h5>
                    <Row>
                        <Col sm={6}>
                            <Form className="card p-4 mb-4" method='post' onSubmit={editSubscriptionUpdate}>
                                {/* <Row className='align-items-center justify-content-between'> */}
                                    <Form.Group as={Col} className="mb-3 text-start">
                                        <Form.Label className={css.label}>Subscription Id</Form.Label>
                                        <Form.Control type="text" name="subId" value={subscriptionEditData.subId ?? ""}  onChange={handleSubscription}  required/>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3 text-start">
                                        <Form.Label className={css.label}>Limit</Form.Label>
                                        <Form.Control type="number" name="limit" value={subscriptionEditData.limit ?? ""}  onChange={handleSubscription}  required/>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-3 mt-4 text-end">
                                        <Button type="submit" className={`rounded-5 ${css.savePackButton} ${css.saveButton}`}>Save</Button>
                                    </Form.Group>
                                {/* </Row> */}
                            </Form>
                        </Col>
                        <Col sm={6}>
                            <table className='table table-border'>
                                <thead>
                                    <tr>
                                        <th>Subscription Id</th>
                                        <th>Limit</th>
                                        <th className='text-end'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subscriptionPack.map((subData:any, subIndex:number) => (
                                        <tr>
                                            <td>{subData.subId}</td>
                                            <td>{subData.limit}</td>
                                            <td className='text-end'>
                                                <div className='d-flex justify-content-end'>
                                                    <button type='button' className='transparentBtn settingTransBtn' onClick={() => editSubscription(subIndex, subData)}>
                                                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                                                    </button>
                                                    <button type='button' className='transparentBtn settingTransBtn' onClick={() => deleteSubscription(subIndex)}>
                                                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {subscriptionPack.length === 0 && (
                                        <tr>
                                            <td colSpan={3} className='text-center'>No Records</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>

            <Form className="card p-4 mb-4" method="post" onSubmit={udpateOnBoard}>
                <div className={`d-flex align-items-center justify-content-between ${css.formContainer}`}>
                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Assets Upload</h5>
                    <Button type='submit' className={`rounded-5 ${css.saveButton}`}>Save</Button>
                </div>
                <Row>
                    <Col md={6}>
                        <h6 className={`mb-3 text-start ${css.label}`}>Onboarding Image/Video - 1 </h6>
                        <div className={`d-flex p-3 gap-2 ${css.imageContainer}`}>
                            <Col md={3} className='asset_section position-relative d-inline-grid'>
                                {onBordingData.onbodingFile1?(
                                    <>
                                        {onBordingData.onbodingFile1Type === "image"?
                                        (<img alt="" src={URL.createObjectURL(onBordingData.onbodingFile1)} className={css.settingFrame}/>)
                                        :
                                        (<video src={URL.createObjectURL(onBordingData.onbodingFile1)}></video>)
                                        }
                                    </>
                                ):(
                                    <>
                                        {onBordingData.filename1 && (
                                            <>
                                                {onBordingData.type1 === "image"?
                                                (<img alt="" src={onBordingData.filename1} className={css.settingFrame}/>)
                                                :
                                                (<video src={onBordingData.filename1}></video>)
                                                }
                                            </>
                                        )}
                                    </>
                                )}
                                <label htmlFor='onbodingFile1' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file' accept="image/*,video/*" id="onbodingFile1" name='onbodingFile1' onChange={handleOnBoardImage} className='d-none' />
                            </Col>
                            <Col md={9}>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Title" name="title1" value={onBordingData.title1} onChange={handleOnBoard}/>
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Description</Form.Label>
                                    <textarea className='form-control' style={{height: '82px'}} placeholder="Enter Description"  name="desc1" value={onBordingData.desc1} onChange={handleOnBoard}></textarea>
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Button-Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Button-Text" name="text1" value={onBordingData.text1} onChange={handleOnBoard}/>
                                </Form.Group>
                            </Col>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h6 className={`mb-3 text-start ${css.label}`}>Onboarding Image/Video - 2 </h6>
                        <div className={`d-flex p-3 gap-2 ${css.imageContainer}`}>
                            <Col md={3} className='asset_section position-relative d-inline-grid'>
                                {onBordingData.onbodingFile2?(
                                    <>
                                        {onBordingData.onbodingFile2Type === "image"?
                                        (<img alt="" src={URL.createObjectURL(onBordingData.onbodingFile2)} className={css.settingFrame}/>)
                                        :
                                        (<video src={URL.createObjectURL(onBordingData.onbodingFile2)}></video>)
                                        }
                                    </>
                                ):(
                                    <>
                                        {onBordingData.filename2 && (
                                            <>
                                                {onBordingData.type2 === "image"?
                                                (<img alt="" src={onBordingData.filename2} className={css.settingFrame}/>)
                                                :
                                                (<video src={onBordingData.filename2}></video>)
                                                }
                                            </>
                                        )}
                                    </>
                                )}
                                <label htmlFor='onbodingFile2' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file' accept="image/*,video/*" name='onbodingFile2' id="onbodingFile2" onChange={handleOnBoardImage} className='d-none'/>
                            </Col>
                            <Col md={9}>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Title</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Title" name="title2" value={onBordingData.title2} onChange={handleOnBoard}/>
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Description</Form.Label>
                                    <textarea className='form-control' style={{height: '82px'}} placeholder="Enter Description" name="desc2" value={onBordingData.desc2} onChange={handleOnBoard}></textarea>
                                </Form.Group>
                                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                                    <Form.Label className={css.label}>Button-Text</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Button-Text" name="text2" value={onBordingData.text2} onChange={handleOnBoard}/>
                                </Form.Group>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Form>
            <Form className="card p-4 mb-4" method="post" onSubmit={updatePurchase}>
                <div className={`d-flex align-items-center justify-content-between ${css.formContainer}`}>
                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Purchase Screen Image/Video</h5>
                    <Button type='submit' className={`rounded-5 ${css.saveButton}`}>Save</Button>
                </div>
                <div>
                    <Row className={css.formContainer}>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start ">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Purchase Screen Image/Video - 1</div>
                                {purchaseData.purchaseFile1 && (
                                    <>
                                        {purchaseData.purchaseFile1Type === "image"?
                                        (<img alt="" src={purchaseData.purchaseFile1} className={css.settingFrame}/>)
                                        :
                                        (<video src={purchaseData.purchaseFile1}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='purchaseFile1' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file' accept="image/*,video/*" id="purchaseFile1" name='purchaseFile1' onChange={handlePurchaseImage} className='d-none' />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Purchase Screen Image/Video - 2</div>
                                {purchaseData.purchaseFile2 && (
                                    <>
                                        {purchaseData.purchaseFile2Type === "image"?
                                        (<img alt="" src={purchaseData.purchaseFile2} className={css.settingFrame}/>)
                                        :
                                        (<video src={purchaseData.purchaseFile2}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='purchaseFile2' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file' accept="image/*,video/*" id="purchaseFile2" name='purchaseFile2' onChange={handlePurchaseImage} className='d-none' />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Purchase Screen Image/Video - 3</div>
                                {purchaseData.purchaseFile3 && (
                                    <>
                                        {purchaseData.purchaseFile3Type === "image"?
                                        (<img alt="" src={purchaseData.purchaseFile3} className={css.settingFrame}/>)
                                        :
                                        (<video src={purchaseData.purchaseFile3}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='purchaseFile3' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file'  accept="image/*,video/*" id="purchaseFile3" name='purchaseFile3' onChange={handlePurchaseImage} className='d-none' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Form className="card p-4 mb-4" method="post" onSubmit={updateSettingPage}>
                <div className={`d-flex align-items-center justify-content-between ${css.formContainer}`}>
                    <h5 className={`mb-3 text-start ${css.formTitle}`}>Settings Page Image/Video</h5>
                    <Button type='submit' className={`rounded-5 ${css.saveButton}`}>Save</Button>
                </div>
                <div>
                    <Row className={css.formContainer}>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start ">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Settings Screen Image/Video - 1</div>
                                {settingFilesData.settingPageFile1 && (
                                    <>
                                        {settingFilesData.settingPageFile1Type === "image"?
                                        (<img alt="" src={settingFilesData.settingPageFile1} className={css.settingFrame}/>)
                                        :
                                        (<video src={settingFilesData.settingPageFile1}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='settingPageFile1' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file'  accept="image/*,video/*" id="settingPageFile1" name='settingPageFile1' onChange={handleSettingImage} className='d-none' />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Settings Screen Image/Video - 2</div>
                                {settingFilesData.settingPageFile2 && (
                                    <>
                                        {settingFilesData.settingPageFile2Type === "image"?
                                        (<img alt="" src={settingFilesData.settingPageFile2} className={css.settingFrame}/>)
                                        :
                                        (<video src={settingFilesData.settingPageFile2}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='settingPageFile2' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file'  accept="image/*,video/*" id="settingPageFile2" name='settingPageFile2' onChange={handleSettingImage} className='d-none' />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} className="d-flex flex-column align-items-start">
                            <div className='asset_section position-relative w-100 d-inline-grid'>
                                <div className={`mb-1 ${css.label}`}>Settings Screen Image/Video - 3</div>
                                {settingFilesData.settingPageFile3 && (
                                    <>
                                        {settingFilesData.settingPageFile3Type === "image"?
                                        (<img alt="" src={settingFilesData.settingPageFile3} className={css.settingFrame}/>)
                                        :
                                        (<video src={settingFilesData.settingPageFile3}></video>)
                                        }
                                    </>
                                )}
                                <label htmlFor='settingPageFile3' className='replace_label'><FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon> Replace</label>
                                <input type='file'  accept="image/*,video/*" id="settingPageFile3" name='settingPageFile3' onChange={handleSettingImage} className='d-none' />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Form>
        </>
    )
}

export default Settings