import css from './index.module.css'
import {
    DatatableWrapper,
    PaginationOptions,
    Pagination,
    TableBody,
    TableHeader,
    TableColumnType,
    Filter
} from 'react-bs-datatable';
import {Col, Row, Table} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import upArrow from '../../assets/download.svg';
import {useEffect, useMemo, useState} from "react";
import API from '../../redux/services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStateIF } from '../../redux/reducers';
import moment from 'moment';
import JSZip from 'jszip';
import arrowLeft from "../../assets/arrow-left.svg";
import DataTable from 'react-data-table-component';

const SocialView = () => {

    const platForms:any = {
        ig : '#DD4242',
        tiktok: '#000000',
        fb: '#2B41FF'
    };

    const initCount = {
            total: {
                total: 0,
                approved: 0,
                rejected: 0
            },
            ig: {
                total: 0,
                approved: 0,
                rejected: 0
            },
            tiktok: {
                total: 0,
                approved: 0,
                rejected: 0
            },
            fb: {
                total: 0,
                approved: 0,
                rejected: 0
            }
    }

    // const headers: TableColumnType<UserData>[] = [
    //     {title: 'Time & Date', prop: 'timeDate',isFilterable: true},
    //     {title: 'Plateform', prop: 'devicetype'},
    //     {title: 'User Name/Device ID.', prop: 'userid',isFilterable: true},
    //     {title: 'Images by Users', prop: 'images'},
    //     {title: 'Upload/Download', prop: 'download'},
    //     {title: 'Approve/Reject', prop: 'action'}
    // ];

    const userDetails = useSelector((state:ReducerStateIF) => state.app);
    const dispatch:any = useDispatch();
    const [body, setBody] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [totalCount, setTotalCount] = useState<any>(initCount)
    
    const [searchData, setSerachData] = useState({
        type:"",
        status: ""
    })

    const columns = useMemo(
        () => [
          {
            name: "Time & Date",
            cell: (row:any) => row.timeDate
          },
          {
            name: "Plateform",
            selector: (row:any) => row.devicetype
          },
          {
            name: "User Name/Device ID.",
            cell: (row:any) => row.userid
          },
          {
            name: "Images by Users",
            selector: (row:any) => row.images
          },
          {
            name: "Upload/Download",
            selector: (row:any) => row.download
          },
          {
            name: "Approve/Reject",
            selector: (row:any) => row.action
          }
        ],
        []
      );

    useEffect(() => { 
        getUserData()
    },[searchData.type, searchData.status, currentPage, rowsPerPage, searchText])

    
    const handleActiveButton = (type: string) => {
        setSerachData((prevState:any) => ({
            ...prevState,
            type: type
        }));
    };

    const getUserData = async() => {

        let search:any = { page: currentPage, per_page: rowsPerPage, search_txt: searchText }
        if(searchData.type !== ""){
            search = {...search, type: searchData.type }
        }

        if(searchData.status !== ""){
            search = {...search, status: searchData.status }
        }

        const response:any = await API.Post("admin/social-view-all-images", search, userDetails, dispatch)
        if(response.status === "success"){
            const tmpData = response.data.data;
            const bodyData = tmpData.map((item:any, index:number) => {
                
                var today = moment();
                var yesterday = moment().subtract(1, 'day');
                var createdAt = moment(item.createdAt).format("hh:mm A, YYYY-MM-DD")
                
                if(moment(item.createdAt).isSame(today, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Today';
                }else if(moment(item.createdAt).isSame(yesterday, 'day')){
                    createdAt = moment(item.createdAt).format("hh:mm A, ")+' Yesterday';
                }


                return {
                    timeDate: createdAt,
                    devicetype: (
                        <div style={{backgroundColor: platForms[item.platform]}} className={css.platForm}>
                            {item.platform}
                        </div>
                    ),
                    userid: item.userId.userid,
                    images: (
                        <div>
                            {/* {item.images.map((img:string, imgIndex:number) => ( */}
                            {((item.images.length > 4)?item.images.slice(0, 4):item.images).map((img:any, imgIndex:number) => (
                                <img key={imgIndex} src={img} alt='' className={css.uploadImage} loading="lazy"/>
                            ))}
                        </div>
                    ),
                    download: (
                        <button type="button" className={`secondary border-0 py-2 px-3 ${css.downloadButton}`} onClick={() => downloadImages(item.images)}>
                            <img alt='' src={upArrow} className="me-2"/>Download
                        </button>),
                    action: (
                        <div  className={`${item.status === "approved" ? css.approveButton : css.rejectedButton}`}>{item.status}</div>
                    // <div className="d-flex gap-2 justify-content-center">
                    //     <button type="button" className={`rounded-2 border-0 ${css.checkIcon}`} onClick={() => actionHandle(item._id, 'approved')}><img alt="" src={check} /></button>
                    //     <button type="button" className={`rounded-2 border-0 py-2 px-3 ${css.crossIcon}`} onClick={() => actionHandle(item._id, 'rejected')}><img alt="" src={cross}/></button>
                    // </div>
                    ),
                }

            });
           setBody(bodyData)
           if(response.data.total.length > 0){
                delete response.data.total[0]._id;
                setTotalCount(response.data.total[0])
           }else{
                setTotalCount(initCount)
           }
        }
    }

    const downloadImages = async(urls:any) => {
        const zip = new JSZip();
    
        const addFileToZip = async (url: string) => {
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.substring(url.lastIndexOf('/') + 1);
            zip.file(fileName, blob);
          } catch (error) {
            console.error(`Error fetching file from ${url}:`, error);
          }
        };
    
        const fetchAndAddFiles = async () => {
          const fetchPromises = urls.map(addFileToZip);
          await Promise.all(fetchPromises);
        };
    
        const downloadZip = async () => {
          try {
            const content = await zip.generateAsync({ type: 'blob' });
            const objectUrl = URL.createObjectURL(content);
    
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'files.zip'; // Set the desired zip file name
            link.click();
    
            URL.revokeObjectURL(objectUrl);
          } catch (error) {
            console.error('Error generating zip file:', error);
          }
        };
    
        await fetchAndAddFiles();
        await downloadZip();
    };

    // const actionHandle = async(imageId:string, status:string) => {
    //     try {
    //         const response:any = await API.Post("admin/user-social-images-status", {imageId: imageId, status: status }, userDetails, dispatch)
    //         if(response.status === "success"){
    //             toast.success(response.message)
    //             getUserData()
    //         }else{
    //             toast.error(response.message)
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const handleStatusButton = (status: string) => {
        setSerachData((prevState:any) => ({
            ...prevState,
            status: status
        }));
    };

    const handlePageChange = (page:number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage:number) => {
        if (!body.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const searchTxt = (e:any) => {
        setSearchText(e.target.value)
    }

    return (
        <>
             <div className={`text-start ${css.userDetail}`}><img alt="" src={arrowLeft}/><span
                style={{opacity: "50%", marginLeft: '16px'}}>Social</span> / View All
            </div>
            <div className={`card p-3 ${css.tableContainer}`}>
                
                    <Row className="mb-4 p-2">
                        <Col xs={12} lg={4} className="d-flex flex-col align-items-center justify-content-between gap-3">
                            <div className={`btn-group ${css.tableButtons}`} role="group" aria-label="Basic example">
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === '' ? css.activeButton : css.allUserFilterButtons}`}
                                        onClick={() => handleActiveButton('')}
                                        style={{borderRadius: '50px 0 0 50px'}}>All <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#BE2BF1"}}>{totalCount.total.total}</span>
                                </button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'ig' ? css.activeButton : css.freeFilterButtons}`}
                                        onClick={() => handleActiveButton('ig')}>IG <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#DD4242"}}>{totalCount.ig.total}</span>
                                </button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'tiktok' ? css.activeButton : css.freeFilterButtons}`}
                                        onClick={() => handleActiveButton('tiktok')}>TikTok <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#000000"}}>{totalCount.tiktok.total}</span>
                                </button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.type === 'fb' ? css.activeButton : css.paidFilterButtons}`}
                                        style={{borderRadius: '0 50px 50px 0'}}
                                        onClick={() => handleActiveButton('fb')}>Fb <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#2B41FF"}}>{totalCount.fb.total}</span>
                                </button>
                            </div>
                        </Col>
                        <Col xs={12} sm={3} md={6} lg={3} className="d-flex  search-input flex-col align-items-end">
                            <InputGroup className={css.searchInput}>
                                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon
                                    icon={faMagnifyingGlass}/></InputGroup.Text>
                                <input name="table-filter" value={searchText} onChange={searchTxt} placeholder="Enter text..." type="text" className="form-control" />
                            </InputGroup>
                        </Col>
                        <Col xs={12} lg={3} className="d-flex flex-col align-items-center justify-content-between gap-3">
                            <div className={`btn-group ${css.tableButtons}`} role="group" aria-label="Basic example">
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.status === '' ? css.activeButton : css.allUserFilterButtons}`}
                                        onClick={() => handleStatusButton('')}
                                        style={{borderRadius: '50px 0 0 50px'}}>All <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#BE2BF1"}}>{totalCount[(searchData.type === "")?'total':searchData.type].total}</span>
                                </button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.status === 'approved' ? css.activeButton : css.allUserFilterButtons}`}
                                        onClick={() => handleStatusButton('approved')}>Approved <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#66BD50"}}>{totalCount[(searchData.type === "")?'total':searchData.type].approved}</span>
                                </button>
                                <button type="button"
                                        className={`d-flex flex-col align-items-center gap-2 ${searchData.status === 'rejected' ? css.activeButton : css.freeFilterButtons}`}
                                        style={{borderRadius: '0 50px 50px 0'}}
                                        onClick={() => handleStatusButton('rejected')}>Rejected <span
                                    className={`px-2 rounded-5 ${css.count}`} style={{background: "#DD4242"}}>{totalCount[(searchData.type === "")?'total':searchData.type].rejected}</span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <DataTable
                        className='imageTable'
                        pagination
                        paginationServer
                        // selectableRows
                        columns={columns}
                        data={body}
                        progressPending={loading}
                        paginationTotalRows={totalCount[(searchData.type === "")?'total':searchData.type][(searchData.status === "")?'total':searchData.status]}
                        selectableRowsNoSelectAll={false}
                        paginationDefaultPage={currentPage}
                        paginationRowsPerPageOptions={[10,20,50,100]}
                        paginationPerPage={rowsPerPage}
                        // onRowMouseEnter={handleMouseEnter}
                        // onRowMouseLeave={handleMouseLeave}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        // onSelectedRowsChange={handleOnSelectedRowsChange}
                        // selectableRowSelected={handleApplySelectedRows}
                    />
            </div>
        </>
    )
}

export default SocialView;